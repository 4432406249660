<template>
  <div class="member-row">
    <div class="member-row-text-field">
      {{ subject_name }}
    </div>
    <div class="member-row-text-field">
      {{ subject_company }}
    </div>
    <div class="member-row-text-field">
      {{ formattedValue }}
    </div>
    <div class="pipeline-row-note-field">
      <TableNoteField :value="note" @pipelineNoteUpdated="handleNoteUpdate" />
    </div>
  </div>
</template>

<script>
import CustomSwitcher from '@/components/common/mainContent/CustomSwitcher.vue'
import TableNoteField from '@/components/common/mainContent/TableNoteField.vue'
import { updateNoteValue } from '../../api/pipelinesApi'

export default {
  props: [
    'id',
    'value',
    'subject_name',
    'subject_company',
    'note',
    'subjectID',
  ],
  components: {
    CustomSwitcher,
    TableNoteField,
  },
  computed: {
    formattedValue() {
      return this.value
        ? '$' + this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.value
    },
  },
  methods: {
    async handleNoteUpdate(newValue) {
      if (newValue !== this.note) {
        await updateNoteValue(this.subjectID, newValue)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styleVars.scss';

.member-row {
  width: calc(100% - 80px);
  height: 44px;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;

  .pipeline-row-note-field {
    width: 250px;
    min-width: 250px;
    height: 20px;
    margin-left: 30px;
    display: block;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }

  .member-row-text-field {
    width: 150px;
    min-width: 150px;
    height: 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }
  .switcher-container {
    width: 150px;
    min-width: 150px;
    //margin-left: 30px;
    display: flex;
    margin-left: auto;
    margin-right: 40px;
    align-items: center;
    justify-content: center;
  }

  .action-btn-container {
    width: 42px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .more-actions-btn {
      border: none;
    }

    .more-actions-btn-active-state {
      border: 1px solid $grayBorderColor;
    }
  }
}
</style>
